import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import { Conference } from "./@types/conference";
import Status from "./Status";
import CommentRule from "./CommentRule";

interface Props {
  apiHost: string;
  conferenceGroupId: string;
}

interface BulkUpdatableConference {
  [bulkUpdateConferenceId: string]: boolean;
}

const App: React.FC<Props> = ({ apiHost, conferenceGroupId }) => {
  const [updating, setUpdating] = useState(false);
  const [bulkUpdateConferences, setBulkUpdateConference] = useState<
    BulkUpdatableConference
  >({});
  const [conferences, setConferences] = useState<Array<Conference>>([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get<Array<Conference>>(
        `${apiHost}/api/live_conferences/`,
        {
          params: { conference_group_id: conferenceGroupId },
          headers: {}
        }
      );
      setConferences(response.data);
      setUpdating(false);
      window.setTimeout(() => fetchData(), 5000);
    };
    fetchData();
  }, [apiHost, conferenceGroupId]);

  const isBulkUpdate = (conferenceId: string) => {
    const bulkUpdateConferenceId = Object.keys(bulkUpdateConferences).find(
      id => id === conferenceId
    );
    if (!bulkUpdateConferenceId) {
      return false;
    }
    return bulkUpdateConferences[bulkUpdateConferenceId];
  };

  const updateConferences = async (url: string) => {
    setUpdating(true);
    const response = await axios.put<Array<Conference>>(url, {
      conference_ids: Object.keys(bulkUpdateConferences).filter(
        id => bulkUpdateConferences[id] === true
      ),
      headers: {}
    });
    const conferencesToUpdate = response.data.map(conference => {
      const currentStateIndex = conferences.findIndex(
        c => c.id === conference.id
      );
      if (currentStateIndex) {
        return { [currentStateIndex]: conference };
      }
      return {};
    });
    setConferences(Object.assign([], conferences, ...conferencesToUpdate));
    setUpdating(false);
  };
  return (
    <div className="webmaster-app">
      <h1>ライブ</h1>
      <table className="table">
        <thead>
          <tr>
            <th>
              <label htmlFor="all-check">
                <input
                  type="checkbox"
                  onChange={event => {
                    const updateState: BulkUpdatableConference = {};
                    for (let conference of conferences) {
                      updateState[conference.id] = event.target.checked;
                    }
                    setBulkUpdateConference(updateState);
                  }}
                />
              </label>
            </th>
            <th>会議名</th>
            <th>PCキーコード</th>
            <th>SPキーコード</th>
            <th>ステータス</th>
            <th>コメントの表示</th>
            <th>ログインユーザー数</th>
            <th>アクティブなセッションのログインユーザー数</th>
            <th>アクティブユーザー数</th>
            <th>コメント数</th>
          </tr>
        </thead>
        <tbody>
          {conferences.map(conference => (
            <tr key={`table-key-${conference.id}`}>
              <td>
                <label htmlFor={`batch-update-${conference.id}`}>
                  <input
                    type="checkbox"
                    checked={isBulkUpdate(conference.id)}
                    onChange={event =>
                      setBulkUpdateConference(
                        Object.assign({}, bulkUpdateConferences, {
                          [conference.id]: event.target.checked
                        })
                      )
                    }
                  />
                </label>
              </td>
              <td>{conference.name}</td>
              <td>
                {conference.operatorKeycode}
                <a
                  href={`${apiHost}/pc/?ok=${conference.operatorKeycode}#login`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="far fa-external-link-alt"></i>
                </a>
              </td>
              <td>
                {conference.userKeycode}
                <a
                  href={`${apiHost}/sp/conferences/${conference.id}/qr/${conference.userKeycodeHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="far fa-external-link-alt"></i>
                </a>
              </td>
              <td>
                <Status
                  track={conference.activeTrack}
                  updating={updating}
                  bulkUpdate={isBulkUpdate(conference.id)}
                />
              </td>
              <td>
                <CommentRule approvalRequired={conference.approvingComments}/>
              </td>
              <td>{conference.loginAudiencesCount}</td>
              <td>{conference.activeLoginAudiencesCount}</td>
              <td>{conference.liveAudiencesCount}</td>
              <td>{conference.commentsCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="btn btn-primary"
        onClick={() => updateConferences(`${apiHost}/api/conference_close/`)}
      >
        チェックした会議をまとめて非アクティブ
      </button>
      <button
        style={{ marginLeft: 20 }}
        className="btn btn-primary"
        onClick={() => updateConferences(`${apiHost}/api/conference_open/`)}
      >
        チェックした会議をまとめてアクティブ
      </button>
    </div>
  );
};

export default App;
