import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const urlParams = new URLSearchParams(window.location.search);
const conferenceGroupId = urlParams.get("conferenceGroupId") || "";
const secret = urlParams.get("secret") || "";

process.env.REACT_APP_SECRET === secret &&
  ReactDOM.render(
    <App
      apiHost={process.env.REACT_APP_API_HOST || ""}
      conferenceGroupId={conferenceGroupId}
    />,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
