import React from "react";
import { Track, TrackMode } from "./@types/conference";

interface Props {
  track: Track;
  updating: boolean;
  bulkUpdate: boolean;
}

const statusLabel = (track: Track) => {
  if (!track) {
    return "非アクティブ"
  }
  let label = ''
  if (track.mode === TrackMode.live) {
    label = "百人会議";
  }
  if (track.mode === TrackMode.question_box) {
    label = "質問箱";
  }
  return `${label}(${track.name})`;
};


export default function(props: Props) {
  if (props.bulkUpdate && props.updating) {
    return <>更新中</>
  }
  return <>{statusLabel(props.track)}</>;
}
