export enum TrackMode {
  live = "live",
  question_box = "question_box"
}
export interface Track {
  name: string;
  mode: TrackMode;
}
export interface Conference {
  approvingComments: boolean;
  id: string;
  name: string;
  operatorKeycode: string;
  userKeycode: string;
  status: string;
  liveAudiencesCount: number;
  loginAudiencesCount: number;
  activeLoginAudiencesCount: number;
  commentsCount: number;
  userKeycodeHash: string;
  options: Array<string>;
  activeTrack: Track;
}
