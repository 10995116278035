import React from "react";

interface Props {
  approvalRequired: boolean;
}

const label = (approvalRequired: boolean): string => {
  if (approvalRequired) {
    return "承認制";
  }
  return "自動承認";
};
export default function(props: Props) {
  return <>{label(props.approvalRequired)}</>;
}
